<div class="services">
  <h5 class="p-8" [ngClass]="SERVICES_SECTIONS[section.value].color">
    {{ SERVICES_SECTIONS[section.value].label }}
  </h5>
  <ul>
    <li
      *ngFor="let service of services?.[section.value]; let i = index; trackBy: 'id' | trackBy"
      class="p-8"
      [ngClass]="i % 2 === 1 ? SERVICES_SECTIONS[section.value].color : ''"
    >
      <label class="services__header">
        <div class="services__header__description">
          <dougs-checkbox
            [ngModel]="servicesModalService.selectedServices.includes(service)"
            (ngModelChange)="servicesModalService.onModelChange($event, service)"
            *ngIf="servicesModalService.isSelectable"
          />
          <span class="pr-16 ml-8">
            {{ service.description }}
            <i [dougsTooltip]="service.remarks" *ngIf="service.remarks" class="fal fa-info-circle"></i>
          </span>
        </div>
        <div class="services__header__right">
          <dougs-icon-button
            *ngIf="
              (userStateService.loggedInUser$ | async).flags.includes(
                USER_FLAG.CAN_ACCESS_AND_UPDATE_SERVICE_TEMPLATES
              ) && isInUserMenu
            "
            (click)="servicesModalService.editService(service)"
            class="services__header__icon"
            iconClass="pen"
            color="secondary"
          />
          <span>{{ service.amount | currency: 'EUR' }}</span>
        </div>
      </label>
      <div class="services__options" *ngFor="let option of service.options; trackBy: 'id' | trackBy">
        <span class="pr-16 pl-8">
          - {{ option.description }}
          <i [dougsTooltip]="option.remarks" *ngIf="option.remarks" class="fal fa-info-circle"></i>
        </span>
        <span>{{ option.amount | currency: 'EUR' }}</span>
      </div>
    </li>
  </ul>
</div>
